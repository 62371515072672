$(function() {
    const modal_holder_selector = '#modal-holder';
    const modal_selector = '.modal';

    $(document).on('click', 'a[data-modal]', function() {
        const location = $(this).attr('href');
        // Load modal dialog from server
        fetch(location, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then(response => response.text())
            .then(data => {
                let holder = document.querySelector(modal_holder_selector);
                holder.innerHTML = data;
                $(holder).find(modal_selector).modal();
                $("input[name='order[giving_type]']").on("change", function(e){
                  holder.querySelectorAll(".giving-section").forEach((section) => {
                    console.log(section.dataset.target);
                    section.classList.add("d-none");
                  });
                });
            });
            
        return false;
    });
    
    // $(document).on('ajax:success', 'form[data-modal]', function(event){
    //     const [data, _status, xhr] = event.detail;
    //     const url = xhr.getResponseHeader('Location');
    //     if (url) {
    //         // Redirect to url
    //         window.location = url;
    //     } else {
    //         // Remove old modal backdrop
    //         // Update modal content
    //         const modal = $(data).find('body').html();
    //         $(modal_holder_selector).innerHtml = modal;
    //         $(modal_holder_selector).find(modal_selector).modal();
    //     }

    //     return false;
    // });
});
