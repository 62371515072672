import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "container", "label", "radioButton", "givingAmount"]

  connect (){
    // var loader = document.createElement("span")
    // loader.classList.add("spinner-border");
    // loader.style = "margin-left:50%;"
    // this.loader = loader;
  }

  change_label(e) {
    if(e.currentTarget.value === "percentage"){
      this.labelTarget.innerText = "%";
      this.givingAmountTarget.setAttribute('max', '100');
    } else {
      this.labelTarget.innerText = this.labelTarget.dataset["currency"];
      this.givingAmountTarget.removeAttribute('max');
    }
  }

  submitForm(e) {
    // this.containerTarget.innerHTML = this.loader.outerHTML;
    this.formTarget.submit();
  }
}
